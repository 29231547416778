// Modules
import React from 'react'
import { Link } from 'gatsby'

// Components
import Container from 'components/global-components/Container'
import Layout from 'components/global-components/Layout'

const NotFoundPage = () => (
  <Layout hasNoHero>
    <Container>
      <section
        className='bottom-section-space'
        style={{ textAlign: 'center' }}
      >
        <h1>404 Not Found</h1>
        <p style={{ marginBottom: '3rem' }}>{'Oops, looks like your path was interrupted. Let\'s go back to the beginning.'}</p>
        <Link
          className='button primary'
          to='/'
        >
          Home Page
        </Link>
      </section>
    </Container>
  </Layout>
)

export default NotFoundPage
